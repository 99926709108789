import { useState } from "react";
import { useEffect } from "react";
import NaijaStates from "naija-state-local-government";
import { Link } from "react-router-dom";
import { findAgents } from "./api";
import { SelectDropDown } from "./icon";
import AgentGiveAwayForm from "modules/users/components/AgentGiveAwayForm";

export default function Index() {
  const countOptions = [10, 20, 50, 100]
  const initFilter = {
    name: "",
    ref_code: "",
    email: "",
    state: "",
    lg: "",
  }
  const [filter, setFilter] = useState(initFilter);

  const ngStates = NaijaStates.states();
  const [selectedState, setSelectedState] = useState("");
  const [lgs, setLGs] = useState([]);
  const [agents, setAgents] = useState([]);
  const [pagAgents, setPageAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(countOptions[0])
  const [total, setTotal] = useState(0)
  const [agent, setAgent] = useState({})
  const [showGA, setShowGA] = useState(false)

  let loadAgents = async () => {
    setIsLoading(true)
    try {
      let response = await findAgents()
      if (response.status !== "SUCCESS") {
      } else {

        setAgents(response.data)
        setPageAgents(preparePages(response.data, page))
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };

  function preparePages(agents, presentPage) {
    let _agents = [...agents].filter(
      e => e.firstname.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.lastname.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.phone.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.address.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.refcode.toLowerCase().includes(filter.name.toLowerCase())
    )

    _agents = [..._agents].filter(
      e => e.state.toLowerCase().includes(filter.state.toLowerCase()) &&
        e.lg.toLowerCase().includes(filter.lg.toLowerCase())
    )
    _agents = [..._agents].sort(function (a, b) {
      return b.created_at - a.created_at;
    });
    setTotal(_agents.length)
    var start = ((presentPage - 1) * pageCount);
    var end = start + pageCount;
    const listPage = _agents.slice(start, end);
    return listPage
  }


  useEffect(() => {
    if (agents.length > 0) {
      const setTOshow = preparePages(agents, page)
      setPageAgents(setTOshow)
    }

  }, [page, pageCount, agents, filter])

  useEffect(() => {
    if (page != 1) {
      setPage(1)
    }

  }, [filter])


  useEffect(() => {
    loadAgents()
    return () => { };
  }, []);

  useEffect(() => {
    if (selectedState != "") {

      setLGs([])
      setLGs([...NaijaStates.lgas(selectedState).lgas]);
      setFilter({ ...filter, lg: "" })

    } else {
      setLGs([])
    }
    return () => { };
  }, [selectedState]);

  function handlePageChange(p) {
    console.log(p)
    setPage(p + 1)
  }
  function handleCountChange(p) {
    setPageCount(p)
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto mx-5">
        <h2 className="text-lg tracking-tight font-extrabold text-gray-900 ">  All Agents </h2>
        <div className="border rounded p-5 bg-gray-100 shadow text-xs">
          <div class="flex lg:flex-row flex-col items-center">
            <div class="flex flex-col lg:mr-10">
              <label for="email" class="text-gray-800  text-sm font-bold leading-tight tracking-normal mb-2" >
                Name or Address
              </label>
              <input name="content" autocomplete="off"
                class="text-gray-600  bg-white  h-10  pl-3   border-gray-300 rounded border"
                onChange={(e) =>
                  setFilter({ ...filter, name: e.target.value })
                }
              />
            </div>

            <div class="flex flex-col lg:mr-16 lg:py-0 py-4">
              <label class="text-gray-800   font-bold leading-tight tracking-normal mb-2" >
                State
              </label>
              <div className="relative w-full sm:w-1/2 md:w-auto">
                <select
                  onChange={(e) => {
                    setFilter({ ...filter, state: e.target.value });
                    setSelectedState(e.target.value);
                  }}
                  className="focus:outline-none border border-gray-400 rounded-lg appearance-none cursor-pointer   py-2 pl-4 pr-10 text-gray-700"  >
                  <option defaultValue="">
                    All States
                  </option>
                  {ngStates.map((state, i) => (
                    <option value={state} key={i}>{state}</option>
                  ))}
                </select>
                <div className="w-4 h-4 absolute m-auto inset-0 mr-4 pointer-events-none cursor-pointer">
                  <SelectDropDown />
                </div>
              </div>
            </div>

            <div class="flex flex-col lg:mr-16 lg:py-0 py-2">
              <label for="last_email" class="text-gray-800  font-bold leading-tight tracking-normal mb-2"  >   Local Government  </label>
              <div className="relative w-full sm:w-1/2 md:w-auto">
                <select
                  onChange={(e) =>
                    setFilter({ ...filter, lg: e.target.value })
                  } className="focus:outline-none border border-gray-400 rounded-lg appearance-none cursor-pointer   py-3 pl-4  pr-10 text-gray-700"
                >
                  <option selected={filter.lg == ""} value="" defaultValue="">
                    All LGs
                  </option>
                  {lgs.map((lg) => (
                    <option selected={filter.lg.toLowerCase() == lg.toLowerCase()} value={lg}>{lg}</option>
                  ))}
                </select>
                <div className="w-4 h-4 absolute m-auto inset-0 mr-4 pointer-events-none cursor-pointer">
                  <SelectDropDown />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>

          {agents.length > 0 && <Pages
            pageCount={pageCount}
            current={page}
            counts={countOptions}
            handleOptChange={(cnt) => handleCountChange(cnt)}
            handlePageChange={(p) => handlePageChange(p)}
            total={total}
          />
          }
        </div>

        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow overflow-auto border-b border-gray-200 my-4">
            <TableData values={agents} pageValues={pagAgents} filter={filter} setAgents={setAgents} setAgent={setAgent} s_agent={agent} isOpen={showGA} setIsOpen={setShowGA} />
          </div>
        </div>
        <div>
          {agents.length > 0 && <Pages
            pageCount={pageCount}
            current={page}
            counts={countOptions}
            handleOptChange={(cnt) => handleCountChange(cnt)}
            handlePageChange={(p) => handlePageChange(p)}
            total={total}
          />
          }
        </div>
       {agent&& <AgentGiveAwayForm agent={agent} isOpen={showGA} setIsOpen={setShowGA}  />}
      </div>
    </div>
  );
}

function Pages({ current, pageCount, handlePageChange, counts, handleOptChange, total }) {
  return (
    <div>
      <div className="block text-sm pt-10 pb-10 overflow-scroll">
        {[...Array(Math.ceil(total / pageCount)).keys()].map((k, i) => <span className={`cursor-pointer rounded-lg px-3 p-2 mx-2 bg-gray-100  ${k + 1 === current && " bg-green-400 text-white"}`} onClick={() => { handlePageChange(k) }} key={i}>{k + 1}</span>)}
      </div>
      <span>
        <select onChange={(e) => handleOptChange(e.target.value)} className="p-2 border rounded-lg m-1">
          {counts.map(o => <option selected={o == pageCount} value={o}>{o}</option>)}
        </select>
      </span>
    </div>

  )
}

function TableData({ values, filter, pageValues, setAgents, s_agent, setAgent, isOpen, setIsOpen }) {
  return (
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="py-3 px-6">  Name </th>
          <th scope="col" className="px-6 py-3">  Phone   </th>
          <th scope="col" className="px-6 py-3">   State  </th>
          <th scope="col" className="px-6 py-3">   LG  </th>
          <th scope="col" className="px-6 py-3">   Date Joined  </th>
          <th scope="col" className="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {pageValues.map((agent, index) => (
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              <Link to={'/agent/' + agent.id} className="text-green-600  "> {agent.firstname} {agent.lastname} ({agent.refcode})</Link>
            </th>
            <td>   {agent.phone}   </td>
            <td>  <h6>{agent.state}</h6>  </td>
            <td >  <h6>{agent.lg}</h6>  </td>
            <td>  <h6>{new Date(agent.created_at * 1000).toDateString()}</h6>   </td>
            <td scope="row">
              <button onClick={()=> {setAgent(agent); setIsOpen(!isOpen)}} className="bg-green-100 px-2 py-1 border">Gift Giveaway</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>)
}
