import React, { useState } from "react";
import { addAdmin, assignRole } from "./api";
import axios from "axios";

const ADMIN_ENDPOINT = process.env.REACT_APP_ENDPOINT_URL;


const GameCheckbox = ({ game, selectedGames, setSelectedGames }) => {
  const handleCheckboxChange = (event) => {
    // console.log(JSON.parse(localStorage.getItem("admin")));
    const { checked } = event.target;

    if (checked) {
      setSelectedGames([
        ...selectedGames,
        {
          role: game.role,
          admin_id: JSON.parse(localStorage.getItem("admin")),
        },
      ]);
    } else {
      setSelectedGames(
        selectedGames.filter((item) => item.admin_id !== game.admin_id)
      );
    }
  };

  return (
    <label className='flex items-center gap-5'>
      <input
        type="checkbox"
        value={game.role}
        checked={selectedGames.some((item) => item.role === game.role)}
        onChange={handleCheckboxChange}
      />
      {game.role}
    </label>
  );
};

const AddNew = () => {
  const [newAdmin, setNewAdmin] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [admin, setAdmin] = useState("");
  const [selectedGames, setSelectedGames] = useState([]);
  const gameData = [
    { id: 1, role: "game-1", admin_id: admin },
    { id: 2, role: "game-2", admin_id: admin },
    { id: 3, role: "game-3", admin_id: admin },
    { id: 4, role: "game-4", admin_id: admin },
    { id: 5, role: "game-5", admin_id: admin },
    { id: 6, role: "game-6", admin_id: admin },
  ];
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };

  let AddAdmin = async () => {
    setIsLoading(true);
    try {
      let response = await addAdmin(newAdmin);
      if (response.status !== "SUCCESS") {
        // console.log(response);
      } else {
        // console.log(response);
        // console.log(response.data.data);
        setAdmin(response.data.data);
        localStorage.setItem("admin", JSON.stringify(response.data.data));
        setShow(true);
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendRequest = () => {
    let adminType = JSON.parse(localStorage.getItem("token"));
    const token = JSON.parse(adminType);
    const accessToken = token.access_token;
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .post(`${ADMIN_ENDPOINT}v1/role`, selectedGames, {
        headers,
      })
      .then((response) => {
        setShow(false);
        localStorage.removeItem("admin");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="my-5 shadow">
      <div className="px-4 py-5 bg-white sm:p-6">
        <h3>Add New Admin</h3>
        <hr />
        <br />
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              {" "}
              Name{" "}
            </label>
            <input
              type="text"
              name="name"
              onChange={(e) => {
                setNewAdmin({ ...newAdmin, name: e.target.value });
              }}
              className="w-full border  p-1 text-sm"
            />
          </div>
          <div className="col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              {" "}
              Email{" "}
            </label>
            <input
              type="email"
              name="email"
              onChange={(e) => {
                setNewAdmin({ ...newAdmin, email: e.target.value });
              }}
              className="w-full border  p-1 text-sm"
            />
          </div>
          <div className="col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              {" "}
              Phone{" "}
            </label>
            <input
              type="phone"
              name="phone"
              onChange={(e) => {
                setNewAdmin({ ...newAdmin, phone: e.target.value });
              }}
              className="w-full border  p-1 text-sm"
            />
          </div>
          <div className="col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              {" "}
              Set Password{" "}
            </label>
            <input
              type="text"
              name="password"
              onChange={(e) => {
                setNewAdmin({ ...newAdmin, password: e.target.value });
              }}
              className="w-full border  p-1 text-sm"
            />
          </div>
          <div className="col-span-3 sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">
              {" "}
              Is Read Only{" "}
            </label>
            <input
              type="checkbox"
              name="password"
              onChange={(e) => {
                setNewAdmin({ ...newAdmin, read_only: e.target.checked });
              }}
              className="w-full border  p-1 text-sm"
            />
          </div>

          <div className="col-span-3 sm:col-span-3">
            <button
              className="bg-gray-700 rounded text-white px-8 py-2 text-sm"
              type="submit"
              onClick={() => {
                AddAdmin();
              }}
            >
              Save
            </button>{" "}
          </div>
        </div>

        {show && (
          <div>
            <div className="flex flex-col my-5">
              <label className="block text-sm font-medium text-gray-700">
                {" "}
                Role{" "}
              </label>

              {gameData.map((game) => (
                <GameCheckbox
                  key={game.id}
                  game={game}
                  selectedGames={selectedGames}
                  setSelectedGames={setSelectedGames}
                />
              ))}
            </div>

            <div className="col-span-3 sm:col-span-3">
              <button
                className="bg-gray-700 rounded text-white px-8 py-2 text-sm"
                type="submit"
                onClick={handleSendRequest}
              >
                Assign Role
              </button>{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNew;
