import { HttpPut } from 'endpoint';
import { HttpGet } from 'endpoint';
import { HttpPost } from 'endpoint';

const ADMIN_ENDPOINT = process.env.REACT_APP_ENDPOINT_URL
const PARTNER_ENDPOINT = process.env.REACT_APP_PARTNER_ENDPOINT

export async function getPartners() {
    const url = `${PARTNER_ENDPOINT}api/partners`
    return HttpGet(url, "")
}

export async function addPartner(data) {
    const url = `${PARTNER_ENDPOINT}api/partner`
    return HttpPost(url, data)
}


export async function updateAPartner(data) {
    const url = `${ADMIN_ENDPOINT}v1/updatepartner/${data.id}`
    return HttpPut(url, data)
}

export async function deleteAPartner(data) {
    const url = `${ADMIN_ENDPOINT}v1/updatepartner/${data.id}`
    return HttpPut(url, data)
}
