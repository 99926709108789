import React, { useEffect } from 'react'
import { useState } from 'react';

function StudioGamePage() {


  const studioPayload = {
    name: "",
    email: "",
    password: "",
    description: "",
  };


  const [newStudio, setNewStudio] = useState(studioPayload)
  const [studios, setStudios] = useState([])

  useEffect(() => {
    loadStudios()
  }, [])


  function loadStudios() {
    const endpoint = "https://closestudio.eliestlotto.biz/api/studios";

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        // Process the studios data here
        setStudios(response.data)
        console.log("Loaded studios:", response);
      })
      .catch((error) => {
        console.error("Error loading studios:", error);
      });
  }

  function saveStudio(newStudio) {
    const endpoint = "https://closestudio.eliestlotto.biz/api/studios";

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newStudio),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save studio");
        }
        return response.json();
      })
      .then((savedStudio) => {
        loadStudios()
        window.location.reload()
        // Process the saved studio data here
        console.log("Saved studio:", savedStudio);
      })
      .catch((error) => {
        console.error("Error saving studio:", error);
      });
  }

  function updateStudio(payload) {
    const endpoint = `https://closestudio.eliestlotto.biz/api/studios/update-status/${payload.id}`;

    fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save studio");
        }
        return response.json();
      })
      .then((savedStudio) => {
        loadStudios()
        window.location.reload()

        // Process the saved studio data here
        console.log("Saved studio:", savedStudio);
      })
      .catch((error) => {
        console.error("Error saving studio:", error);
      });
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8  space-y-10 p-10">
        <div>
          <h1 className='text-center font-bold text-xl mb-5'>STUDIOS</h1>

          <div className='bg-green-50 p-6 rounded mb-10'>
            <div className='flex'>
              <div className='flex-1'>
                <label>Name</label>
                <input className='p-2 border border-gray-200' value={newStudio.name} onChange={(e) => { setNewStudio({ ...newStudio, name: e.target.value }) }} />
              </div>
              <div className='flex-1'>
                <label>Email</label>
                <input className='p-2 border border-gray-200' value={newStudio.email} onChange={(e) => { setNewStudio({ ...newStudio, email: e.target.value }) }} />
              </div>
              <div className='flex-1'>
                <label>Password</label>
                <input className='p-2 border border-gray-200' value={newStudio.password} onChange={(e) => { setNewStudio({ ...newStudio, password: e.target.value }) }} />
              </div>
              <div className='flex-1'>
                <button className='border border-gray-200 p-2 px-5' onClick={() => saveStudio(newStudio)}>Create Studio</button>
              </div>
            </div>
          </div>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className='text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope="col" className="p-2">Name</th>
                <th scope="col" className="p-2">Email</th>
                <th scope="col" className="p-4">Update Password</th>
                <th scope="col" className="p-4"></th>
              </tr>
            </thead>
            <tbody>
              {studios.filter(x => !x.name.includes("Partner")).map(studio => {
                return (
                  <StudioItem studio={studio} updateStudio={(item) => updateStudio(item)} />
                )
              })}
            </tbody>
          </table>
        </div>

        <div>
          <h1 className='text-center font-bold text-xl mb-5 mt-10'>STUDIO PARTNERS</h1>

          <div className='bg-green-50 p-6 rounded mb-10'>
            <div className='flex'>
              <div className='flex-1'>
                <label>Name</label>
                <input className='p-2 border border-gray-200' value={newStudio.name} onChange={(e) => { setNewStudio({ ...newStudio, name: e.target.value }) }} />
              </div>
              <div className='flex-1'>
                <label>Email</label>
                <input className='p-2 border border-gray-200' value={newStudio.email} onChange={(e) => { setNewStudio({ ...newStudio, email: e.target.value }) }} />
              </div>
              <div className='flex-1'>
                <label>Password</label>
                <input className='p-2 border border-gray-200' value={newStudio.password} onChange={(e) => { setNewStudio({ ...newStudio, password: e.target.value }) }} />
              </div>
              <div className='flex-1'>
                <button className='border border-gray-200 p-2 px-5' onClick={() => saveStudio({ ...newStudio, name: `Partner ${newStudio.name}` })}>Create Partner </button>
              </div>
            </div>
          </div>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className='text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope="col" className="p-2">Name</th>
                <th scope="col" className="p-2">Email</th>
                <th scope="col" className="p-4">Password</th>
                <th scope="col" className="p-4"></th>
              </tr>
            </thead>
            <tbody>
              {studios.filter(x => x.name.includes("Partner")).map(studio => {
                return (
                  <StudioItem studio={studio} updateStudio={(item) => updateStudio(item)} />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default StudioGamePage


function StudioItem({ studio, updateStudio }) {
  const [_studio, setStudio] = useState({ ...studio, "password": "" })
  return (
    <tr className='border-b border-gray-200 dark:border-gray-700' key={_studio.id + _studio.name}>
      <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
        <input className='p-2 border' value={_studio.name} onChange={(e) => { setStudio({ ..._studio, name: e.target.value }) }} />
      </td>
      <td className="px-4">{_studio.email}</td>
      <td>
        <input className='p-2 border' placeholder='Update Password here' onChange={(e) => { setStudio({ ..._studio, password: e.target.value }) }} />
      </td>
      <td className="px-4 text-green-500 text-sm">
        <button onClick={() => updateStudio(_studio)}>Update</button>
      </td>
    </tr>
  );
}