import { useState, useEffect } from "react";
import { games, getGames } from "./api";
import axios from "axios";

const ADMIN_ENDPOINT = process.env.REACT_APP_USSD_ENDPOINT;

export default function Index() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [selectedGame, setSelectedGame] = useState("");
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [showSearch, setShowSearch] = useState(true);
  const [filter, setFilter] = useState("false");

  useEffect(() => {
    let adminType = JSON.parse(localStorage.getItem("token"));
    setRole(JSON.parse(adminType));
    // console.log(role);
  }, []);

  useEffect(() => {
    let adminType = JSON.parse(localStorage.getItem("token"));
    const token = JSON.parse(adminType);
    const accessToken = token.access_token;
    // let adminId = role?.roles ? role?.data : "";
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    // console.log(adminId);
    const apiUrl = `${ADMIN_ENDPOINT}v1/game-info?id=${selectedGame}`;
    axios
      .get(apiUrl, {
        headers,
      })
      .then((response) => {
        setShowSearch(true);
        // console.log(response.data);
        setRoleData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [role, selectedGame]);

  function replaceFirstCharacter(str) {
    if (str.length === 0) {
      return "";
    }

    return "234" + str.slice(1);
  }

  // useEffect(() => {
  //   let adminType = JSON.parse(localStorage.getItem("token"));
  //   const token = JSON.parse(adminType);
  //   let searchQuery = replaceFirstCharacter(search);
  //   const accessToken = token.access_token;
  //   const headers = {
  //     "Content-type": "application/json",
  //     Authorization: `Bearer ${accessToken}`,
  //   };
  //   // console.log(searchQuery)
  //   // // console.log(search);
  //   const apiUrl = `https://eliestlotto.biz/v1/search?phone=${searchQuery}&win=${filter}`;
  //   axios
  //     .get(apiUrl, {
  //       headers,
  //     })
  //     .then((response) => {
  //       if (search.length > 0) {
  //         setShowSearch(false);
  //       } else {
  //         setShowSearch(true);
  //       }
  //       console.log(response.data);
  //       setSearchData(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // }, [role, search, filter]);

  const handleSearch = () => {
    let adminType = JSON.parse(localStorage.getItem("token"));
    const token = JSON.parse(adminType);
    let searchQuery = replaceFirstCharacter(search);
    const accessToken = token.access_token;
    const headers = {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    // console.log(searchQuery)
    // // console.log(search);
    const apiUrl = `${ADMIN_ENDPOINT}v1/search?phone=${searchQuery}&win=${filter}`;
    axios
      .get(apiUrl, {
        headers,
      })
      .then((response) => {
        if (search.length > 0) {
          setShowSearch(false);
        } else {
          setShowSearch(true);
        }
        // console.log(response.data);
        setSearchData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  let loadGames = async () => {
    setIsLoading(true);
    try {
      let response = await games();
      if (response.status !== "SUCCESS") {
        alert(response.message);
      } else {
        setData(response.data);
        // window.location.reload()
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  let updateGame = async () => {
    setIsLoading(true);
    try {
      let response = await games();
      if (response.status !== "SUCCESS") {
        alert(response.message);
      } else {
        setData(response.data);
        // window.location.reload()
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGames();
    return () => {};
  }, []);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <h2 className=" text-gray-800   text-lg text-center bg-gray-100">
          Game List
        </h2>

        {role.roles?.length > 0 && (
          <div className="sm:px-6 lg:px-8 my-5 flex justify-between items-center">
            <select
              value={selectedGame}
              onChange={(e) => setSelectedGame(e.target.value)}
              className=" bg-gray-200 p-2 "
            >
              <option value="">Select a game</option>
              {data?.map((game) => (
                <option key={game.id} value={game.id}>
                  {game.test.split(" ")[0]}
                </option>
              ))}
            </select>
            <div className="w-1/2 flex justify-end gap-5">
              <input
                type="search"
                className=" h-8 w-100 rounded-md w-1/2 bg-gray-200 px-2"
                placeholder="Search by phone number eg. 080..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="h-8 rounded-md bg-gray-200 px-2"
              >
                <option value="false">Filter game</option>
                <option value="true">Win</option>
                <option value="false">Loss</option>
              </select>
              <button className="h-8 rounded-md bg-gray-200 px-2" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        )}

        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-auto  border-gray-200 sm:rounded-lg">
            {role.roles?.length === 0 ? (
              <TableData values={data} />
            ) : showSearch ? (
              <RoleTableData roleData={roleData} />
            ) : (
              <SearchResult searchData={searchData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function TableData({ values }) {
  const [entry, setEntry] = useState([...values]);
  const [isTinyLoading, setIsTinyLoading] = useState(false);

  useEffect(() => {
    setEntry([...values]);
    return () => {};
  }, [values]);

  let updateSetting = () => {
    setIsTinyLoading(true);

    fetch(`${process.env.REACT_APP_ENDPOINT_URL}v1/updategames`, {
      method: "PUT", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(entry),
    })
      .then((response) => {
        setIsTinyLoading(false);
        response.json();
      })
      .then((data) => {
        // console.log("Success:", data);
        setIsTinyLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  let resetCount = (id) => {
    setIsTinyLoading(true);

    fetch(`${process.env.REACT_APP_ENDPOINT_URL}v1/resetgame/` + id, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setIsTinyLoading(false);

        response.json();
      })
      .then((data) => {
        setIsTinyLoading(false);
        alert("Game reset complete! set a new target value");
        console.log("Success:", data);
      })
      .catch((error) => {
        alert("Error:" + error);

        console.error("Error:", error);
      });
  };

  return entry.length > 0 ? (
    <div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="font-bold bg-gray-800 text-gray-100">
            <th
              scope="col"
              className="px-6 py-3 text-left   uppercase tracking-wider"
            >
              Game Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left   uppercase tracking-wider"
            >
              Price (Naira)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left   uppercase tracking-wider"
            >
              Winning Count
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left   uppercase tracking-wider"
            >
              Winning Amount
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left    uppercase tracking-wider"
            >
              Winner's Price
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center    uppercase tracking-wider"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <tr>{isTinyLoading && <i class="fas fa-spinner fa-pulse"></i>}</tr>

          {entry.map((game, index) => (
            <tr key={game.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900"> ID: {game.id}</div>
                <input
                  className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-500 dark:text-gray-400"
                  value={game.test}
                  name="test"
                  onChange={(e) => {
                    let copyEntry = [...entry];
                    let target = copyEntry[index];
                    target[e.target.name] = e.target.value;
                    setEntry(copyEntry);
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {game.cost}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <input
                  className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-500 dark:text-gray-400"
                  value={game.count}
                  name="count"
                  onChange={(e) => {
                    let copyEntry = [...entry];
                    let target = copyEntry[index];
                    target[e.target.name] = Number(e.target.value);
                    setEntry(copyEntry);
                  }}
                />
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <input
                  className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-500 dark:text-gray-400"
                  value={game.winningAmount}
                  name="winningAmount"
                  onChange={(e) => {
                    let copyEntry = [...entry];
                    let target = copyEntry[index];
                    target[e.target.name] = Number(e.target.value);
                    setEntry(copyEntry);
                  }}
                />
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <input
                  className="border border-gray-300 dark:border-gray-700 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent placeholder-gray-500 text-gray-500 dark:text-gray-400"
                  value={game.cut}
                  name="cut"
                  onChange={(e) => {
                    let copyEntry = [...entry];
                    let target = copyEntry[index];
                    target[e.target.name] = Number(e.target.value);
                    setEntry(copyEntry);
                  }}
                />
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <a
                  href="/"
                  className="text-indigo-600 hover:text-indigo-900 border-gray-100 shadow px-3 py-2"
                  onClick={() => {
                    updateSetting();
                  }}
                  // className="border-gray-100 shadow px-3 py-2"
                  style={{ margin: 6 }}
                >
                  Save Changes
                </a>

                <a
                  href="#"
                  className="text-gray-100  btn bg-red-500 p-2"
                  onClick={() => {
                    resetCount(game.id);
                  }}
                  style={{ margin: 6 }}
                >
                  Reset Games
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
}

function RoleTableData({ roleData }) {
  // console.log(roleData)
  return (
    //  roleData?.length > 0 ? (
    <>
      <div>
        <div className="font-bold uppercase tracking-wider">
          <p>
            Total Games :{" "}
            <span className="tracking-wide">{roleData?.totalGames}</span>
          </p>
          <p>
            Profit : <span className="tracking-wide">{roleData?.profit}</span>
          </p>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="font-bold bg-gray-800 text-gray-100">
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Phone Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Amount (Naira)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Play
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Guess Two
              </th> */}
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Win
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {/* <tr>
              <i class="fas fa-spinner fa-pulse"></i>
            </tr> */}

            {roleData &&
              roleData?.games?.map((game, i) => (
                <tr key={i}>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <div className="text-sm text-gray-900">+{game.phone}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {game.amount}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                    <div className="text-sm text-gray-900">
                      {game.guess_one}
                    </div>
                  </td>

                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="text-sm text-gray-900">
                      {game.guess_two || '-'}
                    </div>
                  </td> */}

                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                    <div className="text-sm text-gray-900">
                      {JSON.stringify(game.win)}
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                    <div className="text-sm text-gray-900">
                      {new Date(game.time * 1000).toLocaleDateString()}
                    </div>
                  </td>
                </tr>
              ))}

            {roleData?.games?.length === 0 && (
              <div className="font-bold text-gray-900">
                No data currently, please select another game...
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
    // ) : (
    //   <></>
    // );
  );
}

function SearchResult({ searchData }) {
  // console.log(roleData)
  return (
    //  roleData?.length > 0 ? (
    <>
      <div>
        {/* <div className="font-bold uppercase tracking-wider">
          <p>
            Total Games :{" "}
            <span className="tracking-wide">{roleData?.totalGames}</span>
          </p>
          <p>
            Profit : <span className="tracking-wide">{roleData?.profit}</span>
          </p>
        </div> */}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="font-bold bg-gray-800 text-gray-100">
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Phone Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Amount (Naira)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Play
              </th>
              {/* <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Guess Two
              </th> */}
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Win
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-center uppercase tracking-wider"
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {/* <tr>
              <i class="fas fa-spinner fa-pulse"></i>
            </tr> */}

            {searchData &&
              searchData?.map((game, i) => (
                <tr key={i}>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <div className="text-sm text-gray-900">+{game.phone}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {game.amount}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                    <div className="text-sm text-gray-900">
                      {game.guess_one}
                    </div>
                  </td>

                  {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div className="text-sm text-gray-900">
                      {game.guess_two || '-'}
                    </div>
                  </td> */}

                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                    <div className="text-sm text-gray-900">
                      {JSON.stringify(game.win)}
                    </div>
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                    <div className="text-sm text-gray-900">
                      {new Date(game.time * 1000).toLocaleDateString()}
                    </div>
                  </td>
                </tr>
              ))}

            {searchData?.length === 0 && (
              <div className="font-bold text-gray-900">No result found...</div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
