import axios from "axios";

const endpoint = process.env.REACT_APP_ENDPOINT_URL

const http = axios.create({
    baseURL: endpoint,
    headers: {
        "Content-type": "application/json"
    }
});

export function getAllUsers() {
    return http.get(`v1/all-players`);
}

export function createGiveAway(data) {
    return http.post(`/v1/add-giveaway`, data);
}
