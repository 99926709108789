import { Pages } from 'modules/users/views'
import React, { useEffect, useState } from 'react'
import { getAllGiveAways, getAllManualWinners } from '../api'
import ManualWinnersForm from '../components/ManualWinnersForm'

function ManualWinners() {
  const [manualWins, setManualWins] = useState([])
  const countOptions = [10, 20, 50, 100]
  const initFilter = {
    name: "",
    ref_code: "",
    email: "",
    state: "",
    lg: "",
  }

  const [filter, setFilter] = useState(initFilter);
  const [showMW, setShowMW] = useState(false)

  const [pagUsers, setPageUsers] = useState([]);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(countOptions[0])
  const [total, setTotal] = useState(0)


  useEffect(() => {

    getAllManualWinners().then(res => {
      var { data } = res.data
      console.log(data)
      let _manualWins = [...data].sort(function (a, b) {
        return b.created_at - a.created_at;
      });
      setManualWins(_manualWins)
    }).catch(() => {
      alert("error")
    })
  }, [])

  useEffect(() => {
    if (manualWins.length > 0) {
      const setTOshow = preparePages(manualWins, page)
      setPageUsers(setTOshow)
    }

  }, [page, pageCount, manualWins, filter])

  function preparePages(manualWins, presentPage) {
    let _manualWins = [...manualWins].filter(() => true)

    _manualWins = [..._manualWins].filter(() => true)
    _manualWins = [..._manualWins].sort(function (a, b) {
      return a.created_at - b.created_at;
    });
    setTotal(_manualWins.length)
    var start = ((presentPage - 1) * pageCount);
    var end = start + pageCount;
    const listPage = _manualWins.slice(start, end);
    return listPage
  }

  useEffect(() => {
    if (page != 1) {
      setPage(1)
    }

  }, [filter])

  function handlePageChange(p) {
    setPage(p + 1)
  }
  function handleCountChange(p) {
    setPageCount(p)
  }

  function handleAddNew(p) {
    setShowMW(!showMW)
  }

  return (
    <div class="overflow-x-auto relative">

<div className='flex justify-center mb-5'>
        <button className='bg-green-100 px-5 py-2 rounded' onClick={() => handleAddNew()}>Add New</button>
      </div>

      <div>

        {manualWins.length > 0 && <Pages
          pageCount={pageCount}
          current={page}
          counts={countOptions}
          handleOptChange={(cnt) => handleCountChange(cnt)}
          handlePageChange={(p) => handlePageChange(p)}
          total={total}
        />
        }
      </div>

      <div className="p-5 flex text-sm  bg-green-100 space-x-5">
                <h5 className="self-center font-bold">Summary</h5>
                <p className=" self-center">Records:  {manualWins.length} </p>
                {manualWins.length > 0 && <p className=" self-center">Total Count: {[...manualWins].map(x => Number(x.count)).reduce((a, b) => a + b)}</p>
                }
                {manualWins.length > 0 && <p className=" self-center">Total Payout: {[...manualWins].map(x => Number(x.amount) *Number(x.count)).reduce((a, b) => a + b)}</p>
                } </div>
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="py-3 px-6">  Date  </th>
            <th scope="col" class="py-3 px-6">   Count  </th>
            <th scope="col" class="py-3 px-6">   Class  </th>
            <th scope="col" class="py-3 px-6">   Total  </th>
          </tr>
        </thead>
        <tbody>
          {[...pagUsers].map((ga, i) => <ManualWinItem manualWin={ga} />)}
        </tbody>
      </table>
      <ManualWinnersForm isOpen={showMW} setIsOpen={setShowMW} />

    </div>
  )
}

export default ManualWinners

function ManualWinItem({ manualWin }) {
  return <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
    {manualWin.date}
    </th>
    <td>  {manualWin.count}  </td>
    <td>  {manualWin.userType}  </td>
    <td>  {Number(manualWin.amount) * Number(manualWin.count)}  </td>
  </tr>
}