import { useEffect, useState } from "react";
import { getAdmins } from "./api";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let loadAdmins = async () => {
    setIsLoading(true);
    try {
      let response = await getAdmins();
      if (response.status !== "SUCCESS") {
      } else {
        // console.log(response.data);
        setAdmins([...response.data.admins]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadAdmins();
  }, []);

  const admin = admins.filter((admin) => admin.read_only === true);

  return (
    <>
      <div className="flex justify-center items-center">
        <h2 className=" text-gray-800   text-lg text-center bg-gray-100">
          All Admins
        </h2>
        {isLoading && <p className="mt-3">Loading...</p>}
      </div>
      <div className="flex justify-center items-center mt-5">
            <table className="min-w-full divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {" "}
                    Name{" "}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {" "}
                    Email{" "}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {" "}
                    Phone{" "}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {admin?.map((admin) => (
                  <tr key={admin.email}>
                    <td className="px-3 py-2 whitespace-nowrap">
                      {admin.name}
                    </td>

                    <td className="px-3 py-2 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {admin.email}
                      </div>
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {admin.phone}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
      </div>
    </>
  );
};

export default Admins;
