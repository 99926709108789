import { Pages } from 'modules/users/views'
import React, { useEffect, useState } from 'react'
import { getAllGiveAways } from '../api'
import ManualGiveWayForm from '../components/ManualGiveWayForm'

function GiveAways() {
  const [giveAways, setGiveAways] = useState([])
  const countOptions = [10, 20, 50, 100]
  const initFilter = {
    name: "",
    ref_code: "",
    email: "",
    state: "",
    lg: "",
  }

  const [filter, setFilter] = useState(initFilter);
  const [showMG, setShowMG] = useState(false)

  const [pagUsers, setPageUsers] = useState([]);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(countOptions[0])
  const [total, setTotal] = useState(0)

  useEffect(() => {

    getAllGiveAways().then(res => {
      var { data } = res.data
      console.log(data)
      let _giveAways = [...data].sort(function (a, b) {
        return a.created_at - b.created_at;
      });
      setGiveAways(_giveAways)
    }).catch(() => {
      alert("error")
    })
  }, [])

  useEffect(() => {
    if (giveAways.length > 0) {
      const setTOshow = preparePages(giveAways, page)
      setPageUsers(setTOshow)
    }

  }, [page, pageCount, giveAways, filter])

  function preparePages(giveAways, presentPage) {
    let _giveAways = [...giveAways].filter(() => true)

    _giveAways = [..._giveAways].filter(() => true)
    _giveAways = [..._giveAways].sort(function (a, b) {
      return a.created_at - b.created_at;
    });
    setTotal(_giveAways.length)
    var start = ((presentPage - 1) * pageCount);
    var end = start + pageCount;
    const listPage = _giveAways.slice(start, end);
    return listPage
  }

  useEffect(() => {
    if (page != 1) {
      setPage(1)
    }

  }, [filter])

  function handlePageChange(p) {
    setPage(p + 1)
  }
  function handleCountChange(p) {
    setPageCount(p)
  }

  function handleAddNew(p) {
    setShowMG(!showMG)
  }

  return (
    <div class="overflow-x-auto relative">

      <div className='flex justify-center mb-5'>
        <button className='bg-green-100 px-5 py-2 rounded' onClick={() => handleAddNew()}>Add New</button>
      </div>

      <div>

        {giveAways.length > 0 && <Pages
          pageCount={pageCount}
          current={page}
          counts={countOptions}
          handleOptChange={(cnt) => handleCountChange(cnt)}
          handlePageChange={(p) => handlePageChange(p)}
          total={total}
        />
        }
      </div>

      <div className="p-5 flex text-sm  bg-green-100 space-x-5">
                <h5 className="self-center font-bold">Summary</h5>
                <p className=" self-center">Records:  {giveAways.length} </p>
                
                {giveAways.length > 0 && <p className=" self-center">Total Payout: {[...giveAways].map(x => Number(x.amount)).reduce((a, b) => a + b)}</p>
                } </div>
                
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="py-3 px-6">  ID  </th>
            <th scope="col" class="py-3 px-6">   Amount  </th>
            <th scope="col" class="py-3 px-6">   Phone  </th>
            <th scope="col" class="py-3 px-6">   Class  </th>
            <th scope="col" class="py-3 px-6">   Date Given  </th>
            <th scope="col" class="py-3 px-6"> Issued By  </th>
          </tr>
        </thead>
        <tbody>
          {[...pagUsers].map((ga, i) => <GiveAwayItem giveAway={ga} />)}
        </tbody>
      </table>
      <ManualGiveWayForm isOpen={showMG} setIsOpen={setShowMG} />

    </div>
  )
}

export default GiveAways

function GiveAwayItem({ giveAway }) {
  return <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
    <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
      {giveAway.id}
    </th>
    <td>  {giveAway.amount}  </td>
    <td>  {giveAway.userPhone}  </td>
    <td>  {giveAway.userType}  </td>
    <td>   {new Date(giveAway.created_at * 1000).toLocaleDateString()}  </td>
    <td> {giveAway.issuedBy} </td>
  </tr>
}