import React, { useEffect, useState } from 'react'
import { getAllUsers } from '../api'
import GiveAwayForm from '../components/GiveAwayForm'
import UsersList from '../components/UsersList'

function Index() {
    const countOptions = [10, 20, 50, 100]
    const initFilter = {
        msisdn: "",
        yob: "",
        created_at: "",
    }

    const [users, setUsers] = useState([])
    const [filter, setFilter] = useState(initFilter);

    const [pagUsers, setPageUsers] = useState([]);
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(countOptions[0])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getAllUsers().then(res => {
            var { data } = res.data

            console.log(data)
            let _users = [...data].sort(function (a, b) {
                return b.created_at - a.created_at;
            });
            setUsers(_users)
        }).catch(() => {
            alert("error")
        })
    }, [])

    useEffect(() => {
        if (users.length > 0) {
            console.log(users)
            const setTOshow = preparePages(users, page)
            setPageUsers(setTOshow)
        }

    }, [page, pageCount, users, filter])

    function preparePages(users, presentPage) {
        let _users = [...users].filter(e => e.yob.toLowerCase().includes(filter.yob.toLowerCase()))
        _users = [..._users].filter(e => e.msisdn.toLowerCase().includes(filter.msisdn.toLowerCase()))
        _users = [..._users].filter(e => e.yob.toLowerCase().includes(filter.yob.toLowerCase()))
        if (filter.created_at)
            _users = [..._users].filter(e => new Date(e.created_at * 1000).toLocaleDateString().toLowerCase() === filter.created_at.toLowerCase())

        _users = [..._users].filter(() => true)
        _users = [..._users].sort(function (a, b) {
            return b.created_at - a.created_at;
        });
        setTotal(_users.length)
        var start = ((presentPage - 1) * pageCount);
        var end = start + pageCount;
        const listPage = _users.slice(start, end);
        return listPage
    }

    useEffect(() => {
        if (page != 1) {
            setPage(1)
        }

    }, [filter])

    function handlePageChange(p) {
        setPage(p + 1)
    }
    function handleCountChange(p) {
        setPageCount(p)
    }

    return (
        <div class="">

            <div className='p-5 bg-gray-100 text-xs text-gray-700 flex flex-col'>
                <h1>Search Here</h1>
                <div className='space-y-1 md:space-y-0 md:space-x-5 flex flex-col md:flex-row'>
                    <input placeholder='phone number' className='p-2 rounded flex-1' onChange={(e) => setFilter({ ...filter, msisdn: e.target.value })} />
                    <input placeholder='Year of Birth' className='p-2 rounded flex-1' onChange={(e) => setFilter({ ...filter, yob: e.target.value })} />
                    <input placeholder="dd-mm-yyyy" type='date' className='p-2 rounded flex-1 w-full' onChange={(e) => setFilter({ ...filter, created_at:  new Date(e.target.value).toLocaleDateString() })} />
                </div>
            </div>

            <div>
                {users.length > 0 && <Pages
                    pageCount={pageCount}
                    current={page}
                    counts={countOptions}
                    handleOptChange={(cnt) => handleCountChange(cnt)}
                    handlePageChange={(p) => handlePageChange(p)}
                    total={total}
                />
                }
            </div>
            <UsersList users={pagUsers} />
        </div>

    )
}

export function Pages({ current, pageCount, handlePageChange, counts, handleOptChange, total }) {
    return (
        <div className='bg-gray-100 py-5 my-5'>
            <div className="block text-sm overflow-scroll p-2">
                {[...Array(Math.ceil(total / pageCount)).keys()].map((k, i) => <label className={` cursor-pointer rounded-lg px-3 p-5 bg-gray-100  ${k + 1 === current && " bg-green-400 text-white"}`} onClick={() => { handlePageChange(k) }} key={i}>{k + 1}</label>)}
            </div>
            <span>
                <select onChange={(e) => handleOptChange(e.target.value)} className="p-1 border rounded-lg m-1 w-20">
                    {counts.map(o => <option key={o} selected={o == pageCount} value={o}>{o}</option>)}
                </select>
            </span>
        </div>

    )
}

export default Index