import React, { useState } from 'react'
import UserGiveAwayForm from './UserGiveAwayForm'

function UsersList({ users }) {
    const filter= {
        msisdn:"",
        yob:""
    }

    const [user, setUser] = useState({})
    const [showGA, setShowGA] = useState(false)
    return (
        <div class="overflow-x-auto relative">
           
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="py-3 px-6">   Phone Number   </th>
                        <th scope="col" class="py-3 px-6">   YOB  </th>
                        <th scope="col" class="py-3 px-6">   Date Joined  </th>
                        <th scope="col" class="py-3 px-6">   </th>
                    </tr>
                </thead>
                <tbody>
                    {[...users].map((user, i) => <UserItem setUser={setUser} user={user} isOpen={showGA} setIsOpen={setShowGA} />)}
                </tbody>
            </table>
         
            {user && <UserGiveAwayForm isOpen={showGA} setIsOpen={setShowGA} user={user} />}
        </div>
    )
}

export default UsersList

function UserItem({ user, setUser, isOpen, setIsOpen }) {
    return <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {user.msisdn}
        </th>
        <td>  {user.yob}  </td>
        <td>   {new Date(user.created_at * 1000).toLocaleDateString()}  </td>
        <td> <button className='px-3 py-1 border text-xs rounded bg-green-100' onClick={() => { setUser(user); setIsOpen(!isOpen) }}>Gift Giveaway</button> </td>
        <td>  </td>
    </tr>
}
