import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { createManualGW } from '../api'

export default function ManualGiveWayForm({ isOpen, setIsOpen }) {

    const [createPayload, setCreatePayload] = useState({
        "amount": 0,
        "userPhone": "2340xx xxxx xxx",
        "userType": "",
        "issuedBy": "ADMIN",
        "timeline": ""
    })
    const [response, setResponse] = useState("")
    function handleCreateManualWin() {
        setResponse("Loading...")
        createManualGW({ ...createPayload, amount:Number(createPayload.amount) }).then(res => {
            setResponse("Winners record has been added")
        }).catch(() => {
            setResponse("Error Creating giveaway record")
        }).finally(() => {

        })
        //setIsOpen(false)
    }

    return (
        <>
            <Transition show={isOpen} onClose={() => setIsOpen(false)} as={Fragment}>
                <Dialog as="div" className="relative z-10" open={isOpen} onClose={() => setIsOpen(false)}>
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-60" leave="ease-in duration-200" leaveFrom="opacity-60" leaveTo="opacity-0"   >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95"  >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white pt-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title as="h3" className="font-medium leading-6 text-gray-900 px-5"  >
                                        Create Winners Record
                                    </Dialog.Title>
                                    <div className="mt-2 p-5">
                                        <div className='flex flex-row space-x-5'>
                                            <div className='flex-1 border rounded w-full text-sm'>
                                                <label className='p-2 text-xs text-gray-500'>Choose Date</label>
                                                <input placeholder='Date' type='date' className='outline-none bg-gray-100 w-full px-2 py-1' value={createPayload.timeline} onChange={(e) => setCreatePayload({ ...createPayload, timeline: e.target.value })} />
                                            </div>
                                            <div className='flex-1 border rounded w-full'>
                                                <label className='p-2 text-xs text-gray-500'>Amount</label>
                                                <input placeholder='How Many people' type='number' className='outline-none bg-gray-100 w-full px-2 py-1' value={createPayload.amount} onChange={(e) => setCreatePayload({ ...createPayload, amount: e.target.value })} />
                                            </div>
                                        </div>
                                        <div className='flex flex-row space-x-5'>

                                            <div className='flex-1 border rounded w-full'>
                                                <label className='p-2 text-xs text-gray-500'>User Type</label>
                                                <select className='outline-none bg-gray-100 w-full px-2 py-1' onChange={(e) => setCreatePayload({ ...createPayload, userType: e.target.value })}>
                                                <option value="Player"></option>
                                                <option value="Agent">Agent</option>
                                                    <option value="Player">Player</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className='flex flex-row space-x-5'>


                                            <div className='flex-1 border rounded w-full test-center font-bold test-xs items-center'>
                                                {response} </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 space-x-5 bg-gray-100 p-2 justify-end flex">
                                        <button type="button" className="inline-flex justify-center rounded-md border border-transparent bg-green-300 px-4 py-1 text-sm font-sm" onClick={() => setIsOpen(false)}   >
                                            close
                                        </button>
                                        <button type="button" className="inline-flex justify-center rounded-md border border-transparent bg-green-400 px-4 py-1 text-sm font-sm" onClick={() => handleCreateManualWin()}   >
                                            Create Record
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
