import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getDownline, getWalletSummary } from "./api";
import { Link } from 'react-router-dom'
import NaijaStates from "naija-state-local-government";

function AgentDownline({ agents }) {
  const countOptions = [10, 20, 50, 100]
  const initFilter = {
    name: "",
    ref_code: "",
    email: "",
    state: "",
    lg: "",
  }
  const ngStates = NaijaStates.states();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState(initFilter);

  const token = useSelector((state) => state.auth.token);

  const [dWallets, setDWallets] = useState([]);

  const dispatch = useDispatch();



  function preparePages(agents, presentPage) {
    let _agents = [...agents].filter(
      e => e.firstname.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.lastname.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.phone.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.address.toLowerCase().includes(filter.name.toLowerCase()) ||
        e.refcode.toLowerCase().includes(filter.name.toLowerCase())
    )

    _agents = [..._agents].filter(
      e => e.state.toLowerCase().includes(filter.state.toLowerCase()) &&
        e.lg.toLowerCase().includes(filter.lg.toLowerCase())
    )
    _agents = [..._agents].sort(function (a, b) {
      return a.created_at - b.created_at;
    });
    setTotal(_agents.length)
    var start = ((presentPage - 1) * pageCount);
    var end = start + pageCount;
    const listPage = _agents.slice(start, end);
    return listPage
  }

  function handlePageChange(p) {
    console.log(p)
    setPage(p + 1)
  }
  function handleCountChange(p) {
    setPageCount(p)
  }

  const [pagAgents, setPageAgents] = useState([]);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(countOptions[0])
  const [total, setTotal] = useState(0)


  useEffect(() => {
    if (agents.length > 0) {
      const setTOshow = preparePages(agents, page)
      setPageAgents(setTOshow)
    }

  }, [page, pageCount, agents, filter])

  useEffect(() => {
    if (page != 1) {
      setPage(1)
    }

  }, [filter])

  return (
    <>

      <div className="flex items-center justify-between">
        <div className="flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-white ">
          <div className="w-full dark:bg-gray-800 mx-auto downline-center">

            <div className="flex justify-center">
              <div className=" h-auto rounded px-6 py-6 border border-gray-200  mb-10">
                <div>
                  <div className="downline--text text-center">Total Downlines</div>
                  <p className="downline--count text-center">
                    {agents.length}
                  </p>
                </div>
              </div>
              <div className=" h-auto rounded px-6 py-6 border border-gray-200 mb-10">
                <div>
                  <div className="downline--text text-center">Not Active</div>
                  <p className="downline--count text-center">
                    {agents.filter(e => !e.account_verified).length}
                  </p>
                </div>
              </div>
              <div className=" h-auto rounded px-6 py-6 border border-gray-200  mb-10">
                <div>
                  <div className="downline--text text-center">Activated</div>
                  <p className="downline--count text-center">
                    {agents.filter(e => e.account_verified === true).length}
                  </p>
                </div>
              </div>
            </div>


            <div className="flex justify-center self-center my-5">
              <div className="w-full md:w-2/3 flex justify-center self-center space-x-5">
                <input placeholder="Search with name and referral code"
                  onChange={(e) =>
                    setFilter({ ...filter, name: e.target.value })
                  }
                />
                <select onChange={(e) =>
                  setFilter({ ...filter, lg: e.target.value })
                }>
                  <option defaultValue="">
                    All States
                  </option>
                  {ngStates.map((state, i) => (
                    <option value={state} key={i}>{state}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex justify-center self-center">
              <div className="w-full md:w-2/3 flex justify-center self-center flex-col">
                <div>

                  {agents.length > 0 && <Pages
                    pageCount={pageCount}
                    current={page}
                    counts={countOptions}
                    handleOptChange={(cnt) => handleCountChange(cnt)}
                    handlePageChange={(p) => handlePageChange(p)}
                    total={total}
                  />
                  }
                </div>

                <table class="table-auto w-full text-center">
                  <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                    <tr>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-semibold "></div>
                      </th> <th class="p-2 whitespace-nowrap">
                        <div class="font-semibold ">Name</div>
                      </th>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-semibold">Activated</div>
                      </th>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-semibold">Joined</div>
                      </th>
                      <th class="p-2 whitespace-nowrap">
                        <div class="font-semibold "></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="text-sm divide-y divide-gray-100">
                    {pagAgents.map((agent, i) => {
                      return <AgentItem key={i} index={i} agent={agent} token={token} addWallet={(e) => setDWallets([...dWallets, e])} />;
                    })}
                  </tbody>
                </table>
              </div>
            </div>


          </div>
        </div>
      </div>

    </>
  );
}

export default AgentDownline;

function Pages({ current, pageCount, handlePageChange, counts, handleOptChange, total }) {
  return (
    <div>
      <div className="block text-sm pt-10 pb-10 overflow-scroll">
        {[...Array(Math.ceil(total / pageCount)).keys()].map((k, i) => <span className={`cursor-pointer rounded-lg px-3 p-2 mx-2 bg-gray-100  ${k + 1 === current && " bg-green-400 text-white"}`} onClick={() => { handlePageChange(k) }} key={i}>{k + 1}</span>)}
      </div>
      <span>
        <select onChange={(e) => handleOptChange(e.target.value)} className="p-2 border rounded-lg m-1 w-20">
          {counts.map(o => <option selected={o == pageCount} value={o}>{o}</option>)}
        </select>
      </span>
    </div>

  )
}


const AgentItem = ({ agent, token, index, addWallet }) => {
  const [following, setFollowing] = useState(0);
  const [wallet, setWallet] = useState({ id: "" });
  let history = useHistory();

  const getDownlineCount = (agent) => {
    fetch(`${process.env.REACT_APP_ENDPOINT_URL}agent/downline?aid=${agent}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(token).access_token,
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          throw response;
        }
        let data = await response.json();
        setFollowing(data.direct.length)
      })
      .catch((e) => {
        return 0;
      });
  };

  const findFundedWallet = (agent) => {
    fetch(`${process.env.REACT_APP_TRANSACTION_ENDPOINT}find-wallet?owner=${agent}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          throw response;
        }
        let data = await response.json();
        setWallet(data.data.wallet)
        //addWallet(data.data.wallet)
      })
      .catch((e) => {
        return 0;
      });
  };

  useEffect(() => {
    getDownlineCount(agent.refcode)
    return () => { };
  }, []);

  useEffect(() => {
    findFundedWallet(agent.id)
    return () => { };
  }, []);


  return (
    <tr>
      <td class="p-2 whitespace-nowrap">
        <div class="">{index + 1}</div>
      </td> <td class="p-2 whitespace-nowrap">
        <div class="">{agent.lastname} {agent.firstname}</div>
      </td>
      <td class="p-2 whitespace-nowrap">
        <div class="">{agent.account_verified ? "YES" : "NO"}</div>
      </td>
      <td class="p-2 whitespace-nowrap">
        <div class="">{new Date(agent.created_at * 1000).toLocaleDateString()}</div>
      </td>
      <td class="p-2 whitespace-nowrap">{wallet.id &&
        <Link to={{ pathname: "/agent/transactions", state: { wallet: wallet } }} className="p-1 bg-red-200 rounded-lg text-xs">View Funding</Link>
      }    </td>
    </tr>

  );
};
