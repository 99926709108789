import axios from "axios";

const endpoint = process.env.REACT_APP_ENDPOINT_URL

const http = axios.create({
    baseURL: endpoint,
    headers: {
        "Content-type": "application/json"
    }
});

export function getAllGiveAways() {
    return http.get(`v1/all-giveaways`);
}

export function getAllManualWinners() {
    return http.get(`v1/all-m-winners`);
}


export function createManualWin(data) {
    return http.post(`/v1/add-m-winner`, data);
}

export function createManualGW(data) {
    return http.post(`/v1/add-giveaway`, data);
}

export function createManualGames(data) {
    return http.post(`/v1/create-games`, data);
}