import { Pages } from 'modules/users/views'
import React, { useEffect, useState } from 'react'
import { createManualGames, getAllGiveAways } from '../api'
import ManualGiveWayForm from '../components/ManualGiveWayForm'

function Generator() {

  let timeInUnix = (hour, minute, sec) => {
    var time = new Date;
    time.setHours(hour);
    time.setMinutes(minute);
    time.setSeconds(sec);
    var timestamp = Math.floor(time / 1000);
    return timestamp
  }

  const [payload, setPayload] = useState({
    amount: 0,
    time: 0,
    count: 0,
    from: timeInUnix(0, 0, 0),
    to: timeInUnix(23, 59, 59)
  });

  const [loading, setLoading] = useState(false)
  let eod = 0
  let sod = 0;
  const handleTimelineChange = (e) => {
    if (e.target.value) {
      eod = new Date(e.target.value).setHours(23, 59, 59);
      sod = new Date(e.target.value).setHours(0, 0, 0);
    }

    
    setPayload({ ...payload, from: Math.floor(sod / 1000), to: Math.floor(eod / 1000), time: eod })
  }

  function GenerateGames() {
    if (payload.amount === 0 | payload.count === 0 || payload.time === 0) {
      return
    }
    setLoading(true)
    createManualGames(payload).then(res => {
      alert("Records Created")
      setLoading(false)
    }).catch(() => {
      alert("Failed Created")
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div class="overflow-x-auto relative">
      <div className='flex flex-col space-y-2 border p-2 rounded-md'>
        <h5 className='font-bold'>Generate Games</h5>

        <label className='text-xs'>Game Amount</label>
        <input placeholder='Game Amount' type='number' value={payload.amount} onChange={(e) => setPayload({ ...payload, amount: Number(e.target.value) })} className='p-2 bg-gray-100' />
        <label className='text-xs'>How Many</label>
        <input placeholder='How Many' type='number' value={payload.count} onChange={(e) => setPayload({ ...payload, count: Number(e.target.value) })} className='p-2 bg-gray-100' />
        <label className='text-xs'>Target Date</label>
        <input placeholder='' type='date' className='p-2 bg-gray-100' onChange={(e) => handleTimelineChange(e)} />
        {loading && <p className='text-red-500 text-xs text-center my-1'>loading ...</p>}
        <button onClick={() => GenerateGames()} className='bg-green-800 p-2 text-white my-1 rounded'>Generate</button>
      </div>
    </div>
  )
}

export default Generator

